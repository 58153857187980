<a
    *ngIf="item.route"
    class="flex items-center px-2"
    (click)="onClick()"
    [routerLink]="item.route"
    routerLinkActive="link-active"
    [class.btn-disabled]="item.disabled"
    [tabIndex]="item.disabled ? -1 : 0"
>
    <app-icon *ngIf="item.icon" [name]="item.icon" class="icon-5 mr-2"></app-icon>
    <h5 class="inline-block py-3 leading-4" translate>{{ item.name }}</h5>

    @switch (item.route) {
        @case ('/chat') {
            <div class="ml-auto pr-4">
                @if (unreadMessagesCount$ | async; as count) {
                    <app-indicator size="sm" [amount]="count" />
                }
            </div>
        }
    }
</a>

<div *ngIf="item.title" class="flex items-center px-2 text-dark bg-gradient-tertiary">
    <app-icon *ngIf="item.icon" [name]="item.icon" class="icon-5 mr-2 icon-dark"></app-icon>
    <h5 class="inline-block py-1 leading-4" translate>{{ item.name }}</h5>
</div>
