import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { UploaderService } from '../services/uploader.service';

export function initUploaderGuard(): CanActivateFn {
    return () => {
        const uploaderServie = inject(UploaderService);
        if (uploaderServie) {
            return true;
        }
        return false;
    };
}
