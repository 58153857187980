import { MenuItem } from '../models/menu.model';

export const SidebarMobileItems: MenuItem[] = [
    {
        icon: 'user',
        name: 'profile.title',
        route: '/profile',
    },
    {
        icon: 'distribution',
        name: 'sidebar.distribution.catalog',
        route: '/distribution/catalogue',
    },
    {
        icon: 'check',
        name: 'my_business',
        route: '/my_business',
        disabled: true,
    },
    {
        icon: 'bell',
        name: 'notifications',
        route: '/notifications',
        disabled: true,
    },
    {
        icon: 'message',
        name: 'messages',
        route: '/chat',
    },
    {
        icon: 'claims',
        name: 'file_claim',
        route: '/claims',
        disabled: true,
    },

    {
        name: 'education',
        title: true,
    },
    {
        name: 'adm_blog',
        route: '/education/blog',
        disabled: true,
    },
    {
        name: 'adm_e_learning',
        route: '/education/elearning',
        disabled: true,
    },
];

export const SidebarUnverifiedMobileItems: MenuItem[] = [
    {
        icon: 'user',
        name: 'profile.title',
        route: '/profile',
    },
];

export const SidebarUnverifiedMobileBottomItems: MenuItem[] = [
    {
        name: 'logout',
        route: '/logout',
    },
];

export const SidebarMobileBottomItems: MenuItem[] = [
    {
        name: 'contact_us',
        route: '/contact',
        disabled: true,
    },
    {
        name: 'logout',
        route: '/logout',
    },
];
