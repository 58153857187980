import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { IconsModule, SharedModule } from '@app/shared';
import { I18nModule } from '@app/i18n';
import { NavBottomComponent } from './components/nav-bottom/nav-bottom.component';

@NgModule({
    imports: [CommonModule, IconsModule, SharedModule, I18nModule, RouterModule],
    declarations: [NavBottomComponent],
    exports: [NavBottomComponent],
})
export class NavBottomModule {}
