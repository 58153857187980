<nav>
    <ul class="flex items-center align-middle justify-around list-none w-full h-full">
        <li class="p-2 w-16" *ngFor="let menuItem of menu">
            <a [routerLink]="menuItem.route" class="flex flex-col justify-center items-center" routerLinkActive="active" #rla="routerLinkActive">
                <app-icon *ngIf="menuItem.icon" [name]="menuItem.icon" class="icon-6" />
                <div class="text-[0]" @Grow *ngIf="rla.isActive">
                    <span class="text-primary text-3xs leading-none pt-2 block">{{ menuItem.name | translate }}</span>
                </div>
            </a>
        </li>
    </ul>
</nav>
