import { Injectable, ComponentRef, ApplicationRef, Renderer2, RendererFactory2, createComponent, inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { UploaderComponent } from '../components/uploader/uploader.component';
import { UploaderFacade } from '@app/store/uploader';
import { distinctUntilChanged, map, skip, tap } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class UploaderService {
    private readonly document = inject(DOCUMENT);
    private readonly applicationRef = inject(ApplicationRef);
    private readonly rendererFactory2 = inject(RendererFactory2);
    private readonly uploaderFacade = inject(UploaderFacade);

    private uploaderComponentRef: ComponentRef<UploaderComponent> | null = null;
    private renderer2: Renderer2;

    constructor() {
        this.renderer2 = this.rendererFactory2.createRenderer(null, null);

        this.uploaderFacade.files$
            .pipe(
                map((files) => files.length > 0),
                distinctUntilChanged(),
                skip(1),
                tap((active) => (active ? this.attach() : this.detach())),
            )
            .subscribe();
    }

    attach(): void {
        this.uploaderComponentRef = createComponent(UploaderComponent, {
            environmentInjector: this.applicationRef.injector,
        });
        this.applicationRef.attachView(this.uploaderComponentRef.hostView);
        this.renderer2.appendChild(this.document.querySelector('body'), this.uploaderComponentRef.location.nativeElement);
    }

    detach() {
        if (this.uploaderComponentRef) {
            this.renderer2.removeChild(this.document.querySelector('body'), this.uploaderComponentRef);
            this.applicationRef.detachView(this.uploaderComponentRef.hostView);
            this.uploaderComponentRef.destroy();
            this.uploaderComponentRef = null;
        }
    }
}
