import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, HostBinding, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { map } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { UploaderFacade } from '@app/store/uploader';
import { OpenClose } from '../../animations/open-close.animation';
import { Slide } from '../../animations/slide.animation';

@Component({
    selector: 'app-uploader',
    templateUrl: './uploader.component.html',
    styleUrls: ['./uploader.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [CommonModule, TranslateModule],
    standalone: true,
    animations: [OpenClose, Slide],
})
export class UploaderComponent {
    private uplodareFacede = inject(UploaderFacade);
    private destroyRef = inject(DestroyRef);

    pending$ = this.uplodareFacede.pending$;
    filesInQueue$ = this.uplodareFacede.files$.pipe(
        takeUntilDestroyed(this.destroyRef),
        map((files) => files.filter((file) => file.status === 'queue').length),
    );

    @HostBinding('@slide') get(): void {}
}
