import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ShellPanel, ShellAuth } from './shells';
import { userLoaded, verificationStatusGuard } from './store/user';
import { UserLoggedInGuard, UserNotLoggedInGuard } from './shared/guards';
import { eventsCleanup } from './store/events';
import { RefreshSocialsComponent } from './shared/components';
import { initUploaderGuard } from './modules/uploader';
import { singleCatalogueCleanup } from './store/single-catalogue';
import { mediaCleanup } from './store/media';
import { singleDraftsCleanup } from './store/single-drafts';
import { socialMediaCleanup } from './store/social-media';
import { chatListCleanup } from './store/chat-list';

const routes: Routes = [
    {
        path: '',
        canActivateChild: [userLoaded(), initUploaderGuard()],
        children: [
            {
                path: '',
                redirectTo: 'dashboard',
                pathMatch: 'full',
            },
            {
                path: '',
                canActivate: [UserNotLoggedInGuard],
                children: [
                    ShellAuth.childRoutes([
                        {
                            path: 'auth',
                            loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
                        },
                    ]),
                ],
            },
            {
                path: '',
                canActivate: [UserLoggedInGuard],
                canDeactivate: [eventsCleanup(), singleCatalogueCleanup, mediaCleanup, socialMediaCleanup, chatListCleanup],
                children: [
                    ShellPanel.childRoutes([
                        {
                            path: 'profile',
                            loadChildren: () => import('./profile/profile.module').then((m) => m.ProfileModule),
                        },
                        {
                            path: '',
                            canActivateChild: [verificationStatusGuard],
                            children: [
                                {
                                    path: 'setup',
                                    loadChildren: () => import('./setup/setup.module').then((m) => m.SetupModule),
                                },

                                {
                                    path: 'verification',
                                    loadChildren: () => import('./verification/verification.module').then((m) => m.VerificationModule),
                                },
                                {
                                    path: 'dashboard',
                                    redirectTo: 'distribution/catalogue',
                                    // loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
                                },
                                {
                                    path: 'analytics',
                                    loadChildren: () => import('./analytics/analytics.module').then((m) => m.AnalyticsModule),
                                },

                                {
                                    path: 'temp',
                                    redirectTo: 'storybook',
                                },
                                {
                                    path: 'storybook',
                                    loadChildren: () => import('./storybook/storybook.module').then((m) => m.StorybookModule),
                                },
                                {
                                    path: 'distribution',
                                    canDeactivate: [singleDraftsCleanup],
                                    loadChildren: () => import('./distribution/distribution.module').then((m) => m.DistributionModule),
                                },
                                {
                                    path: 'marketing',
                                    loadChildren: () => import('./marketing/marketing.module').then((m) => m.MarketingModule),
                                },
                                {
                                    path: 'ecommerce',
                                    loadChildren: () => import('./ecommerce/ecommerce.module').then((m) => m.EcommerceModule),
                                },
                                {
                                    path: 'booking',
                                    loadChildren: () => import('./booking/booking.module').then((m) => m.BookingModule),
                                },
                                {
                                    path: 'calendar',
                                    loadChildren: () => import('./calendar/calendar.module').then((m) => m.CalendarModule),
                                },
                                {
                                    path: 'chat',
                                    loadChildren: () => import('./chat/chat.module').then((m) => m.ChatModule),
                                },
                                {
                                    path: 'education',
                                    loadChildren: () => import('./education/education.module').then((m) => m.EducationModule),
                                },
                                {
                                    path: 'claims',
                                    loadChildren: () => import('./claims/claims.module').then((m) => m.ClaimsModule),
                                },
                                {
                                    path: 'contact',
                                    loadChildren: () => import('./contact/contact.module').then((m) => m.ContactModule),
                                },
                                {
                                    path: 'fallback',
                                    loadChildren: () => import('./redirect/redirect.module').then((m) => m.RedirectModule),
                                },
                            ],
                        },
                    ]),
                    {
                        path: 'logout',
                        loadChildren: () => import('./logout/logout.module').then((m) => m.LogoutModule),
                    },
                ],
            },
        ],
    },
    {
        path: 'refresh-socials',
        component: RefreshSocialsComponent,
    },
    {
        path: '**',
        redirectTo: 'dashboard',
        pathMatch: 'full',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [],
})
export class AppRoutingModule {}
