import { DOCUMENT } from '@angular/common';
import { ApplicationRef, ComponentRef, Injectable, Injector, OnDestroy, Renderer2, createComponent, inject } from '@angular/core';
import { BehaviorSubject, Subject, Subscription, combineLatest, delay, distinctUntilChanged, map, tap } from 'rxjs';
import { BackdropComponent } from '@app/shared/components';

import { SidebarMobileComponent } from '../components/sidebar-mobile/sidebar-mobile.component';
import { WindowService } from '@app/shared/services';

@Injectable()
export class SidebarMobileService implements OnDestroy {
    private readonly applicationRef = inject(ApplicationRef);
    private readonly document = inject(DOCUMENT);
    private readonly renderer2 = inject(Renderer2);
    private readonly injector = inject(Injector);
    private readonly windowService = inject(WindowService);

    private componentRef?: ComponentRef<SidebarMobileComponent>;
    private backdropRef?: ComponentRef<BackdropComponent>;

    private readonly isOpen$ = new BehaviorSubject<boolean>(false);
    private readonly subscriptions$ = new Subscription();
    private backdropClick$?: Subject<null>;
    private backdropClickSub$?: Subscription;

    constructor() {
        this.subscriptions$.add(
            combineLatest([this.windowService.lg$, this.isOpen$])
                .pipe(
                    map(([lg, open]) => lg === false && open === true),
                    distinctUntilChanged(),
                    delay(0),
                    tap((open) => (open ? this.attach() : this.detach())),
                )
                .subscribe(),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions$.unsubscribe();
    }

    toggle() {
        this.isOpen$.next(!this.isOpen$.value);
    }

    private attach() {
        this.componentRef = createComponent(SidebarMobileComponent, {
            environmentInjector: this.applicationRef.injector,
            elementInjector: this.injector,
        });
        this.backdropRef = createComponent(BackdropComponent, {
            environmentInjector: this.applicationRef.injector,
            elementInjector: this.injector,
        });

        this.renderer2.appendChild(this.document.body, this.componentRef.location.nativeElement);
        this.renderer2.appendChild(this.document.body, this.backdropRef.location.nativeElement);
        this.applicationRef.attachView(this.componentRef.hostView);
        this.applicationRef.attachView(this.backdropRef.hostView);

        this.backdropClick$ = this.backdropRef.instance.click$;
        this.startBackclickWatch();
    }

    private detach() {
        this.stopBackclickWatch();
        if (this.componentRef) {
            this.applicationRef.detachView(this.componentRef.hostView);
            this.componentRef.destroy();
        }
        if (this.backdropRef) {
            this.applicationRef.detachView(this.backdropRef.hostView);
            this.backdropRef.destroy();
        }
    }

    private startBackclickWatch() {
        this.backdropClickSub$ = this.backdropClick$?.pipe(tap(() => this.toggle())).subscribe();
    }

    private stopBackclickWatch() {
        this.backdropClickSub$?.unsubscribe();
        this.backdropClick$ = undefined;
    }
}
