import { Component, Input } from '@angular/core';
import { NavItem } from '../../../models/nav-item.model';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
})
export class MenuComponent {
    @Input({ required: true }) menu!: NavItem[];
}
