<nav class="h-full flex flex-col">
    <header class="flex items-center ps-2">
        <h3 translate class="uppercase">menu</h3>
        <button class="block leading-none text-[0] p-1 ml-auto mr-3" (click)="close()">
            <app-icon name="close" class="flex icon-4 icon-light sidebar-icon" />
        </button>
    </header>

    <ul class="shadow-box-black-25">
        <li *ngFor="let item of menu$ | async">
            <app-sidebar-mobile-item [item]="item" (clicked)="close()" />
        </li>
    </ul>

    <ul class="mt-auto">
        <li *ngFor="let bottomItem of bottomMenu$ | async">
            <app-sidebar-mobile-item [item]="bottomItem" (clicked)="close()" />
        </li>
    </ul>
</nav>
