<header class="border-b border-gray-100 w-full bg-white fixed top-0">
    <div class="mx-auto flex items-stretch">
        <div>
            <a routerLink="dashboard">
                <img class="block min-w-[5rem] max-w-[5rem] w-21 | sm:min-w-[6rem] sm:max-w-[6rem]" src="/assets/svgs-optimized/logo-adm.svg" alt="Logo" />
            </a>
        </div>

        <div class="border-l border-gray-100 self-center py-6 px-8">
            <a class="btn btn--filled" routerLink="/auth/sign-in">{{ 'header.login' | translate }}</a>
        </div>
    </div>
</header>
