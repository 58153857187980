import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { ChatListFacade } from '@app/store/chat-list';
import { MenuItem } from '../../../../models/menu.model';

@Component({
    selector: 'app-sidebar-mobile-item',
    templateUrl: './sidebar-mobile-item.component.html',
    styleUrls: ['./sidebar-mobile-item.component.scss'],
})
export class SidebarMobileItemComponent {
    private readonly chatListFacade = inject(ChatListFacade);

    @Input({ required: true }) item!: MenuItem;

    @Output() clicked = new EventEmitter();

    readonly unreadMessagesCount$ = this.chatListFacade.unreadMessagesCount$;

    onClick() {
        this.clicked.emit();
    }
}
