import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VersionModule } from '@app/modules/version';
import { IconsModule } from '@app/shared';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';

import { FooterComponent } from './components/footer/footer.component';
import { LogoComponent } from './components/logo/logo.component';
import { LogoutComponent } from './components/logout/logout.component';
import { MenuComponent } from './components/menu/menu.component';

@NgModule({
    imports: [CommonModule, VersionModule, IconsModule, TranslateModule, RouterModule],
    declarations: [FooterComponent, LogoComponent, LogoutComponent, MenuComponent],
    exports: [FooterComponent, LogoComponent, LogoutComponent, MenuComponent],
})
export class ShellSharedModule {}
