<ng-container *ngIf="{ lg: (lg$ | async), verificationStatus: verificationStatus$ | async } as data">
    <app-header *ngIf="data.lg" />
    <app-header-mobile *ngIf="!data.lg" />

    <div
        class="grid"
        [ngClass]="{
            'grid-cols-[auto,1fr]': (lg$ | async) === true && data.verificationStatus === 'verified',
        }"
    >
        <app-sidebar *ngIf="data.lg && data.verificationStatus === 'verified'" />

        <main
            class="p-4 lg:p-6 flex flex-col overflow-x-hidden"
            [ngClass]="{ 'max-w-[67.5rem] justify-self-center w-full': data.verificationStatus !== 'verified' }"
        >
            <router-outlet />
        </main>
    </div>

    <app-nav-bottom *ngIf="!data.lg && data.verificationStatus === 'verified'" />
</ng-container>
