<div class="toolbar" *ngIf="{ verificationStatus: verificationStatus$ | async } as data">
    <a
        app-button
        routerLink="/calendar"
        routerLinkActive="active"
        severity="neutral"
        icon="calendar"
        [text]="'header.calendar' | translate"
        [disabled]="data.verificationStatus !== 'verified'"
    ></a>
    <div class="relative">
        <a
            app-button
            routerLink="/chat"
            routerLinkActive="active"
            severity="neutral"
            icon="message"
            [text]="'header.news' | translate"
            [disabled]="data.verificationStatus !== 'verified'"
        ></a>

        @if (unreadMessagesCount$ | async; as count) {
            <app-indicator size="sm" [amount]="count" class="absolute -top-1 -right-1" />
        }
    </div>
    <a app-button severity="neutral" icon="affairs" [text]="'header.business' | translate" [disabled]="true"></a>
    <a app-button severity="neutral" icon="bell" [disabled]="true"></a>
</div>
