<div>
    <button type="button" (click)="togglePopover()" class="gap-1 flex items-center">
        <ng-content />
    </button>

    <div *ngIf="open$ | async" class="absolute top-full right-0 mt-1 p-2 rounded-lg bg-gradient-gray grid gap-4">
        <a routerLink="/profile" class="typo-subtitle1 py-[0.1875rem] whitespace-nowrap">{{ 'header.popover_menu.my_profile' | translate }}</a>

        <app-separator type="row" />

        <a routerLink="/logout" class="logout-btn">{{ 'header.popover_menu.logout' | translate }}</a>
    </div>
</div>
