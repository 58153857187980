import { HttpClient } from '@angular/common/http';
import { HttpService } from '@corelabs/angular-core';
import { Injectable } from '@angular/core';
import { CacheInterceptor } from '../interceptors/cache.interceptor';

declare module '@angular/common/http' {
    export interface HttpClient {
        addResponseCaching(): HttpClient;
    }
}

@Injectable({
    providedIn: 'root',
})
export class HttpCoreService extends HttpService {
    override addResponseCaching(): HttpClient {
        return this.addInterceptor(CacheInterceptor);
    }
}
