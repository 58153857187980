import { Component } from '@angular/core';
import { SidebarMenuItems } from '../../../../configs/config';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
    readonly menu = SidebarMenuItems;
}
