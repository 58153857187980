import { Component, OnInit, inject } from '@angular/core';
import { ChatListFacade } from '@app/store/chat-list';
import { UserFacade } from '@app/store/user';

@Component({
    selector: 'app-header-toolbar',
    templateUrl: './header-toolbar.component.html',
    styleUrls: ['./header-toolbar.component.scss'],
})
export class HeaderToolbarComponent implements OnInit {
    private readonly userFacade = inject(UserFacade);
    private readonly chatListFacade = inject(ChatListFacade);

    readonly verificationStatus$ = this.userFacade.verificationStatus$;
    readonly unreadMessagesCount$ = this.chatListFacade.unreadMessagesCount$;

    ngOnInit(): void {
        this.chatListFacade.getList();
    }
}
