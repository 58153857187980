import { Component, EventEmitter, HostBinding, Input, inject } from '@angular/core';
import { map } from 'rxjs';
import { UserFacade } from '@app/store/user';
import { SidebarMobileService } from '../../services/sidebar-mobile.service';
import { Init } from '../../animations/init.animation';
import {
    SidebarMobileBottomItems,
    SidebarMobileItems,
    SidebarUnverifiedMobileBottomItems,
    SidebarUnverifiedMobileItems,
} from '../../../../configs/sidebar-mobile';

@Component({
    selector: 'app-sidebar-mobile',
    templateUrl: './sidebar-mobile.component.html',
    styleUrls: ['./sidebar-mobile.component.scss'],
    animations: [Init],
})
export class SidebarMobileComponent {
    private readonly sidebarMobileService = inject(SidebarMobileService);
    private readonly userFacade = inject(UserFacade);

    readonly verificationStatus$ = this.userFacade.verificationStatus$;
    readonly menu$ = this.verificationStatus$.pipe(map((status) => (status === 'verified' ? SidebarMobileItems : SidebarUnverifiedMobileItems)));
    readonly bottomMenu$ = this.verificationStatus$.pipe(
        map((status) => (status === 'verified' ? SidebarMobileBottomItems : SidebarUnverifiedMobileBottomItems)),
    );

    @Input() clickEvent: EventEmitter<boolean> = new EventEmitter();

    @HostBinding('@init') get(): void {}

    clickEvent$ = this.clickEvent.subscribe((v) => (v ? this.close() : ''));

    close() {
        this.sidebarMobileService.toggle();
    }
}
