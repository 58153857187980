import { MenuItem } from '../models/menu.model';

export const MenuItems: MenuItem[] = [
    {
        icon: 'distribution',
        name: 'navigation.distribution',
        route: '/distribution',
    },
    {
        icon: 'analytics',
        name: 'navigation.analytics',
        route: '/analytics',
    },
    {
        icon: 'social-media',
        name: 'navigation.marketing',
        route: '/marketing',
    },
    {
        icon: 'ecommerce',
        name: 'navigation.ecommerce',
        route: '/ecommerce',
    },
    {
        icon: 'concert',
        name: 'navigation.booking',
        route: '/booking',
    },
];

export const SidebarMenuItems: MenuItem[] = [
    // {
    //     icon: 'dashboard',
    //     name: 'navigation.dashboard',
    //     route: '/dashboard',
    // },
    {
        icon: 'distribution',
        name: 'navigation.distribution',
        route: '/distribution',
        submenu: [
            {
                name: 'sidebar.distribution.release_album',
                route: '/distribution/cd',
                disabled: true,
            },
            {
                name: 'sidebar.distribution.plan_premiere',
                route: '/distribution/planning',
            },
            {
                name: 'sidebar.distribution.catalog',
                route: '/distribution/catalogue',
            },
        ],
    },
    {
        icon: 'analytics',
        name: 'navigation.analytics_full',
        route: '/analytics',
        submenu: [
            {
                name: 'sidebar.analytics.finance',
                route: '/analytics/finance',
            },
            {
                name: 'sidebar.analytics.marketing',
                route: '/analytics/marketing',
            },
        ],
    },
    {
        icon: 'social-media',
        name: 'navigation.marketing',
        route: '/marketing',
        submenu: [
            {
                name: 'sidebar.marketing.plans',
                route: '/marketing/plans',
                disabled: true,
            },
            {
                name: 'sidebar.marketing.planner',
                route: '/marketing/planner',
                disabled: true,
            },
            {
                name: 'sidebar.marketing.paid_ads',
                route: '/marketing/paid-ads',
                disabled: true,
            },
            {
                name: 'sidebar.marketing.press_note',
                route: '/marketing/press-note',
                disabled: true,
            },
            {
                name: 'sidebar.marketing.send_single',
                route: '/analytics/send-single',
                disabled: true,
            },
        ],
    },
    {
        icon: 'ecommerce',
        name: 'navigation.ecommerce',
        route: '/ecommerce',
        submenu: [
            {
                name: 'sidebar.ecommerce.order_merch',
                route: '/ecommerce/order-merch',
                disabled: true,
            },
            {
                name: 'sidebar.ecommerce.report_preorder',
                route: '/ecommerce/report-preorder',
                disabled: true,
            },
            {
                name: 'sidebar.ecommerce.add_own_products',
                route: '/ecommerce/add-products',
                disabled: true,
            },
            {
                name: 'sidebar.ecommerce.order_landing_page',
                route: '/ecommerce/order-landing-page',
                disabled: true,
            },
            {
                name: 'sidebar.ecommerce.catalog',
                route: '/ecommerce/catalog',
                disabled: true,
            },
        ],
    },
    {
        icon: 'concert',
        name: 'navigation.booking',
        route: '/booking',
        submenu: [
            {
                name: 'booking.buttons.label_offer',
                disabled: true,
            },
            {
                name: 'booking.buttons.label_calendar',
                disabled: true,
            },
            {
                name: 'booking.buttons.label_tixy',
                disabled: true,
            },
            {
                name: 'booking.buttons.label_order_manager',
                disabled: true,
            },
        ],
    },
    {
        icon: 'dashboard',
        name: 'navigation.education',
        route: '/education',
        submenu: [
            {
                name: 'sidebar.education.adm_blog',
                route: '/education/blog',
                disabled: true,
            },
            {
                name: 'sidebar.education.adm_e_learning',
                route: '/education/elearning',
                disabled: true,
            },
        ],
    },
    {
        icon: 'claims',
        name: 'navigation.claims',
        route: '/claims',
        disabled: true,
    },
    {
        icon: 'contact',
        name: 'navigation.contact',
        route: '/contact',
        disabled: true,
    },
];
