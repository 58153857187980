import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploaderStoreModule } from '@app/store/uploader';

@NgModule({
    declarations: [],
    imports: [CommonModule, UploaderStoreModule],
    exports: [],
    providers: [],
})
export class UploaderModule {}
