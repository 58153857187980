import { Component } from '@angular/core';
import { Grow } from '../../animations/grow.animation';
import { MenuItems } from '../../../../configs/config';

@Component({
    selector: 'app-nav-bottom',
    templateUrl: './nav-bottom.component.html',
    styleUrls: ['./nav-bottom.component.scss'],
    animations: [Grow],
})
export class NavBottomComponent {
    readonly menu = MenuItems;
}
