import { Injectable, inject } from '@angular/core';
import { AccountFacade, AccountMessagesService } from '@corelabs/angular-account';
import { MessageService } from '@corelabs/angular-messages';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, tap } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class MessagesBusService {
    private readonly messageService = inject(MessageService);
    private readonly accountMessagesService = inject(AccountMessagesService);
    private readonly accountFacade = inject(AccountFacade);
    private readonly translateService = inject(TranslateService);
    private readonly subscriptions$ = new Subscription();

    constructor() {
        this.subscriptions$.add(
            this.accountMessagesService.messages$
                .pipe(
                    tap((data) => {
                        const message = this.translateService.instant(`cl.account.${data.key}`);
                        this.messageService.clear();
                        this.messageService.add(message, data.type, data.code, data.key);
                    }),
                )
                .subscribe(),
        );

        this.subscriptions$.add(
            this.accountFacade.error$
                .pipe(
                    tap((error) => {
                        switch (error.message) {
                            case 'invalid_credentials':
                                this.messageService.add(this.translateService.instant('cl.account.login-invalid-credentials'), 'danger');
                                break;

                            case 'user.account.blocked.exception':
                                this.messageService.add(this.translateService.instant('cl.account.account-blocked'), 'danger');
                                break;

                            default:
                                this.messageService.add(this.translateService.instant(error.message), 'danger');
                        }
                    }),
                )
                .subscribe(),
        );
    }
}
