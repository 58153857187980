import { trigger, transition, animate, style } from '@angular/animations';

export const Grow = trigger('Grow', [
    transition(':enter', [
        style({
            opacity: '0',
            height: '0',
        }),
        animate(
            '0.3s cubic-bezier(0.5, 0, 0.1, 1)',
            style({
                opacity: '1',
                height: '*',
            }),
        ),
    ]),
    transition(':leave', [
        animate(
            '0.3s cubic-bezier(0.5, 0, 0.1, 1)',
            style({
                opacity: '0',
                height: '0',
            }),
        ),
    ]),
]);
