import { Component, inject } from '@angular/core';
import { menu } from '../../configs/menu';
import { WindowService } from '@app/shared/services';
import { SidebarMobileService } from '../../modules/sidebar-mobile';
import { UserFacade } from '@app/store/user';

@Component({
    selector: 'app-shell',
    templateUrl: './shell.component.html',
    styleUrls: ['./shell.component.scss'],
    providers: [SidebarMobileService],
})
export class ShellPanelComponent {
    private readonly windowService = inject(WindowService);
    private readonly userFacade = inject(UserFacade);

    readonly menu = menu;
    readonly lg$ = this.windowService.lg$;
    readonly verificationStatus$ = this.userFacade.verificationStatus$;
}
