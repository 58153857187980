<ng-container *ngIf="{ verificationStatus: verificationStatus$ | async } as data">
    <div class="item" [class.active]="rla.isActive">
        <a
            class="block pointer-events-none"
            [class.btn-disabled]="item.disabled"
            [ngClass]="{ 'pointer-events-none opacity-50 ': data.verificationStatus !== 'verified' }"
            [routerLink]="item.route"
            routerLinkActive
            #rla="routerLinkActive"
            [tabindex]="data.verificationStatus !== 'verified' || item.disabled ? -1 : 0"
        >
            <div class="flex items-center">
                <div class="p-2 text-[0] mr-1">
                    <app-icon *ngIf="item.icon" [name]="item.icon" class="icon-6" />
                </div>
                <span class="typo-subtitle2">{{ item.name | translate }}</span>
            </div>
        </a>
        <ul [@Grow] *ngIf="(item.submenu?.length || 0) > 0 && rla.isActive">
            <li *ngFor="let submenuItem of item.submenu">
                <app-sidebar-subitem [item]="submenuItem" />
            </li>
        </ul>
    </div>
</ng-container>
