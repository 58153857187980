import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { IconsModule, SharedModule } from '@app/shared';
import { ButtonComponent } from '@app/shared/components';
import { I18nModule } from '@app/i18n';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SidebarItemComponent } from './components/sidebar-item/sidebar-item.component';
import { SidebarSubitemComponent } from './components/sidebar-subitem/sidebar-subitem.component';

@NgModule({
    imports: [CommonModule, IconsModule, SharedModule, I18nModule, RouterModule, ButtonComponent],
    declarations: [SidebarComponent, SidebarItemComponent, SidebarSubitemComponent],
    exports: [SidebarComponent],
})
export class SidebarModule {}
