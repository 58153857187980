import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { IconsModule, SharedModule } from '@app/shared';
import { I18nModule } from '@app/i18n';
import { ButtonComponent, IndicatorComponent } from '@app/shared/components';
import { SidebarMobileComponent } from './components/sidebar-mobile/sidebar-mobile.component';
import { SidebarMobileItemComponent } from './components/sidebar-mobile-item/sidebar-mobile-item.component';

@NgModule({
    imports: [CommonModule, IconsModule, SharedModule, I18nModule, RouterModule, ButtonComponent, IndicatorComponent],
    declarations: [SidebarMobileComponent, SidebarMobileItemComponent],
})
export class SidebarMobileModule {}
