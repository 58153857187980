import { trigger, transition, animate, style } from '@angular/animations';

export const Slide = trigger('slide', [
    transition(':enter', [
        style({
            transform: 'translateX(calc(100% + 2rem))',
        }),
        animate(
            '0.4s cubic-bezier(0.5, 0, 0.1, 1)',
            style({
                transform: 'translateX(0)',
            }),
        ),
    ]),
    transition(':leave', [
        animate(
            '0.4s cubic-bezier(0.5, 0, 0.1, 1)',
            style({
                transform: 'translateX(calc(100% + 2rem))',
            }),
        ),
    ]),
]);
