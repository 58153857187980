import { ChangeDetectorRef, Component, ElementRef, NgZone, OnDestroy, OnInit, inject } from '@angular/core';
import { BehaviorSubject, Subscription, filter, fromEvent, tap } from 'rxjs';

@Component({
    selector: 'app-popover-menu',
    templateUrl: './popover-menu.component.html',
    styleUrls: ['./popover-menu.component.scss'],
})
export class PopoverMenuComponent implements OnInit, OnDestroy {
    private readonly ngZone = inject(NgZone);
    private readonly elementRef = inject(ElementRef);
    private readonly cdr = inject(ChangeDetectorRef);

    private readonly subscriptions$ = new Subscription();
    readonly open$ = new BehaviorSubject(false);

    ngOnInit() {
        this.ngZone.runOutsideAngular(() => {
            this.subscriptions$.add(
                fromEvent(document, 'mousedown')
                    .pipe(
                        filter((event) => !this.elementRef.nativeElement.contains(event.target)),
                        tap(() => this.onClickOutside()),
                    )
                    .subscribe(),
            );
        });
    }

    ngOnDestroy() {
        this.subscriptions$.unsubscribe();
    }

    togglePopover() {
        this.open$.next(!this.open$.value);
    }

    private onClickOutside() {
        this.ngZone.run(() => {
            this.open$.next(false);
            this.cdr.detectChanges();
        });
    }
}
