<nav *ngIf="{ verificationStatus: (verificationStatus$ | async) } as data">
    <ul *ngIf="data.verificationStatus !== 'unverified'">
        <li>
            <button class="p-2" (click)="toggleSidebar()">
                <app-icon [name]="'union'" class="icon-4 icon-light" />
            </button>
        </li>
        <li>
            <button class="p-1" [tabIndex]="-1" class="btn-disabled">
                <app-icon [name]="'bell'" class="icon-6" [ngClass]="data.verificationStatus === 'verified' ? 'icon-light' : 'icon-light/30'" />
            </button>
        </li>
    </ul>

    <app-logo class="h-[1.375rem] mx-auto justify-center" />

    <ul *ngIf="data.verificationStatus !== 'unverified'">
        <li>
            <button
                class="p-1 relative"
                [disabled]="data.verificationStatus !== 'verified'"
                [class.active]="rla.isActive"
                routerLink="/chat"
                routerLinkActive="active"
                #rla="routerLinkActive"
            >
                <app-icon
                    [name]="'message'"
                    class="icon-6 icon-light"
                    [class.icon-primary]="rla.isActive"
                    [ngClass]="data.verificationStatus === 'verified' ? 'icon-light' : 'icon-light/30'"
                />

                @if (unreadMessagesCount$ | async; as count) {
                    <app-indicator class="absolute top-[11px] left-4" [class.text-primary]="rla.isActive" size="sm" [amount]="count" />
                }
            </button>
        </li>
        <li>
            <a routerLink="/calendar" class="p-1" #calendarLinkActive="routerLinkActive" routerLinkActive="active">
                <app-icon [name]="'calendar'" class="icon-6 icon-light" [class.icon-primary]="calendarLinkActive.isActive" />
            </a>
        </li>
    </ul>
</nav>
