<div *ngIf="user$ | async as user" class="flex items-center relative max-w-xs">
    <span class="typo-subtitle1 truncate" [title]="user.pseudonym">{{ user.pseudonym }}</span>

    <app-popover-menu #popover>
        <ng-container *ngIf="user.avatar | fileUrl | async as url; else placeholderIcon">
            <div class="ml-2 w-8 h-8 rounded-full flex border-white border p-px">
                <img class="object-cover rounded-full w-full" [src]="url" alt="" />
            </div>
        </ng-container>

        <ng-template #placeholderIcon>
            <div class="ml-2 bg-black-quanternary rounded-full p-1 flex justify-center border-white border">
                <app-icon name="avatar" class="icon-[1.375rem] icon- pb-px fill-text-primary" />
            </div>
        </ng-template>

        <app-icon [name]="(popover.open$ | async) ? 'times' : 'union-left'" class="icon-light icon-4" />
    </app-popover-menu>
</div>
