import { Component, Input, inject } from '@angular/core';
import { MenuItem } from '../../../../models/menu.model';
import { Grow } from '../../animations/grow.animation';
import { UserFacade } from '@app/store/user';

@Component({
    selector: 'app-sidebar-item',
    templateUrl: './sidebar-item.component.html',
    styleUrls: ['./sidebar-item.component.scss'],
    animations: [Grow],
})
export class SidebarItemComponent {
    private readonly userFacade = inject(UserFacade);

    readonly verificationStatus$ = this.userFacade.verificationStatus$;

    @Input({ required: true }) item!: MenuItem;
}
