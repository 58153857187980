import { Component, Input } from '@angular/core';
import { MenuItem } from '../../../../models/menu.model';

@Component({
    selector: 'app-sidebar-subitem',
    templateUrl: './sidebar-subitem.component.html',
    styleUrls: ['./sidebar-subitem.component.scss'],
})
export class SidebarSubitemComponent {
    @Input({ required: true }) item!: MenuItem;
}
