import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { IconsModule, SharedModule } from '@app/shared';
import { I18nModule } from '@app/i18n';
import { ChatListStoreModule } from '@app/store/chat-list';
import { ShellSharedModule } from '../../shared/shell-shared.module';
import { SidebarMobileModule } from './modules/sidebar-mobile/sidebar-mobile.module';
import { SidebarModule } from './modules/sidebar/sidebar.module';
import { NavBottomModule } from './modules/nav-bottom/nav-bottom.module';

import { ButtonComponent, IndicatorComponent, SeparatorComponent } from '@app/shared/components';
import { ShellPanelComponent } from './components/shell/shell.component';
import { HeaderMobileComponent } from './components/header-mobile/header-mobile.component';
import { HeaderComponent } from './components/header/header.component';
import { HeaderToolbarComponent } from './components/header/toolbar/header-toolbar.component';
import { HeaderUserComponent } from './components/header/user/header-user.component';
import { PopoverMenuComponent } from './components/header/popover-menu/popover-menu.component';
import { FileUrlPipe } from '@app/shared/pipes';

@NgModule({
    imports: [
        CommonModule,
        IconsModule,
        SharedModule,
        I18nModule,
        ShellSharedModule,
        RouterModule,
        SidebarModule,
        SidebarMobileModule,
        NavBottomModule,
        ButtonComponent,
        IndicatorComponent,
        SeparatorComponent,
        FileUrlPipe,
        ChatListStoreModule,
    ],
    declarations: [ShellPanelComponent, HeaderMobileComponent, HeaderComponent, HeaderToolbarComponent, HeaderUserComponent, PopoverMenuComponent],
})
export class ShellPanelModule {}
