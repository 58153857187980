import { Component, inject } from '@angular/core';
import { UserFacade } from '@app/store/user';
import { share } from 'rxjs';

@Component({
    selector: 'app-header-user',
    templateUrl: './header-user.component.html',
    styleUrls: ['./header-user.component.scss'],
})
export class HeaderUserComponent {
    private readonly userFacade = inject(UserFacade);

    readonly user$ = this.userFacade.user$.pipe(share());
}
